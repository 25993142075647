import { useEffect, useRef, useState } from "react";
import { VideoEngineContext } from "../../contexts/video-engine";
import { initializationCam } from '../../components/face-app/util';
import { Button, Snackbar } from "@material-ui/core";
import { VideoEngine } from '@3divi/face-sdk-video-engine-js';
import CameraScreen from "../../components/face-app/camera-screen/camera-screen";
import { notify } from "../../util/notify";
import { NotifyType } from "../../util/app-config";

function Camera() {
    const VIDEO_ID = 'video';
    const CANVAS_ID = 'canvasOutput';

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const videoRef = useRef({});
    const [videoEngine, setVideoEngine] = useState(null);
    const [isDetected, setDetected] = useState(false);
    const [bestPrediction, setBestPrediction] = useState(null);

    // Conditions for getting bestshot
    const [bestShotConditions, setBestShotConditions] = useState({
        eyesDistanceInRequiredRange: false,
        poseInRequiredRange: false,
    });

    // Conditions for 2D Liveness
    const [livenessConditions, setLivenessConditions] = useState({
        headWasTurned: false,
        eyesWasBlinking: false,
    });

    // The state of rotation of the head
    const [headTurns, setHeadTurns] = useState({
        up: false,
        down: false,
        left: false,
        right: false,
    });

    useEffect(() => {
        setLoading(true);
        initDemo();
    }, []);

    const { message } = notify();

    const initDemo = async () => {
        try {
            setError('');
            await initializationCam(VIDEO_ID, CANVAS_ID);
            const videoEngine = new VideoEngine({});
            await videoEngine.load();
            setVideoEngine(videoEngine);
            setLoading(false);
        } catch (error) {
            if(error && error.message === "Error: NotReadableError: Could not start video source"){
                setError('The camera is already accessed by other application/tab!');
            } else {
                setError(error.message);
            }
        }
    };

    return (
        <VideoEngineContext.Provider
            value={{
                input: videoRef.current,
                videoEngine,
                status: {
                    loading,
                    isDetected,
                },
                actions: {
                    setDetected,
                    setHeadTurns,
                    setBestPrediction,
                    setBestShotConditions,
                    setLivenessConditions,
                },
                bestPrediction,
                constants: {
                    videoId: VIDEO_ID,
                    canvasId: CANVAS_ID,
                },
            }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(error)}
                message={error}
                action={<Button variant="contained" color="primary" onClick={initDemo}>Retry</Button>} />
            <CameraScreen
                bestShotConditions={bestShotConditions}
                livenessConditions={livenessConditions}
                headTurns={headTurns}
                videoRef={videoRef} />
        </VideoEngineContext.Provider>
    );
}
export default Camera;