import React, { useContext, useState, useEffect } from "react";
import { VideoEngineContext } from "../../../contexts/video-engine";
import {
  drawCanvasFrame,
  clearCanvasFrame,
  vidOff,
  drawPhotoCanvas,
} from "../util";
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Snackbar,
} from "@material-ui/core";
import Controls from "../Controls";
import Assistant from "../Assistant";
import DetectionProgressTimer from "../DetectionProgressTimer";
import { AppContext } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { useApiService, useContextService } from "../../../services";
import { PageUrl } from "../../../util/app-config";


function CameraScreen({
  videoRef,
  bestShotConditions,
  livenessConditions,
  headTurns,
}) {
  const { constants, input, status, actions, bestPrediction, videoEngine } =
    useContext(VideoEngineContext);
  const {
    setDetected,
    setHeadTurns,
    setBestPrediction,
    setLivenessConditions,
    setBestShotConditions,
  } = actions;
  const [initialization, setInitialization] = useState(false);
  const [isInitialized, setisInitialized] = useState(false);
  const { videoId, canvasId } = constants;
  const { loading, isDetected } = status;
  const [isPersonLivenessDetected, setLivenessDetected] = useState(false);
  const appContext = useContext(AppContext);
  const imageContext = appContext.image;
  const navigationContext = appContext.navigation;
  const registrationContext = appContext.registration;
  const navigate = useNavigate();
  const { validateImage,updateRegistration, saveFile  } = useApiService();
  const { putContextRegistration, patchContextRegistrationAttachment } = useContextService();

  const [photoValidationResult, setPhotoValidity] = useState({
    status: 2,
    errorMessage: "",
  });
  const [validatingPhoto, setValidating] = useState(false);
  const [isValidPhoto, setIsValidPhoto] = useState(false);
  const [photovalidationmessage, setPhotovalidationmessage] = useState("");
  const [isPhotoDrawnInCanvas, setPhotoDrawInCanvas] = useState(false);
  const [countDown, setCountdown] = useState();
  const [countDownStarted, setCountdownStarted] = useState(false);
  const [imageData, setImageData] = useState();

  const canvasStyle = {
    width: 300,
    height: 400,
  };

  useEffect(() => {
    const getImage = async () => {
      if (
        bestPrediction &&
        bestPrediction.imageBase64 &&
        livenessConditions?.eyesWasBlinking &&
        livenessConditions?.headWasTurned
      ) {
        handlerStopDetect();
        setLivenessDetected(true);
        imageContext.imageBase64Liveness = `data:image/jpeg;base64,${bestPrediction.imageBase64}`;
        const base64Response = await fetch(
          `data:image/jpeg;base64,${bestPrediction.imageBase64}`
        );
        var blob = await base64Response.blob();
        imageContext.imageBlobLiveness = blob;
      }
    };

    getImage();
  }, [bestPrediction, JSON.stringify(livenessConditions)]);

  useEffect(() => {
    if (isPersonLivenessDetected) {
      setCountdownStarted(true);
      setCountdown(5);
    }
  }, [isPersonLivenessDetected]);

  useEffect(() => {
    if (countDown) {
      if (countDown == 1) {
        setCountdownStarted(false);
        var base64 = drawPhotoCanvas();
        setPhotoDrawInCanvas(true);
        setImageData(base64);
      }
      const interval = setInterval(() => {
        setCountdown(countDown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countDown]);

  useEffect(() => {
    const getImage = async () => {
      if (imageData) {
        setValidating(true);
        let base64String = imageData.replace("data:image/jpeg;base64,", "");
        const base64Response = await fetch(
          `data:image/jpeg;base64,${base64String}`
        );
        var photoBlob = await base64Response.blob();
        var validationResult = await validateImage(
          imageContext.imageBlobLiveness,
          photoBlob
        );
        setValidating(false);

        if (validationResult === null || validationResult.status == 0) {
          setPhotovalidationmessage(validationResult.errorMessage);
          setIsValidPhoto(false);
          imageContext.imageBase64 = undefined;
          imageContext.imageBlob = undefined;
        }
        if (validationResult.status == 1) {
          imageContext.imageBase64 = imageData;
          imageContext.imageBlob = photoBlob;
          setIsValidPhoto(true);
          setPhotovalidationmessage(validationResult.errorMessage);
        }
      }
    };
    getImage();
  }, [imageData]);

  useEffect(() => {
    if (videoEngine && !isPersonLivenessDetected) {
      handlerStartDetect();
    }
  });

  // A function that is called each time during detection processing
  const detectionCallback = (prediction) => {
    if (videoEngine.isInitialised) {
      setInitialization(false);
      setisInitialized(true);
    }

    if (prediction && videoEngine.inProgress) {
      setDetected(true);

      // Check turns head
      checkTurnsHead(prediction);

      // Best prediction and best shot
      const bestPrediction = videoEngine.bestPrediction;
      setBestShotConditions((prevState) => {
        const newState = {
          eyesDistanceInRequiredRange:
            prediction.face.eyes.eyesDistanceInRequiredRange,
          poseInRequiredRange: prediction.pose.poseInRequiredRange,
        };
        return JSON.stringify(newState) !== JSON.stringify(prevState)
          ? newState
          : prevState;
      });
      if (bestPrediction) setBestPrediction(bestPrediction);

      // 2D Liveness
      setLivenessConditions((prevState) => {
        const newState = {
          headWasTurned: prediction.headWasTurned,
          eyesWasBlinking: prediction.face.eyes.wasBlinked,
        };
        return JSON.stringify(newState) !== JSON.stringify(prevState)
          ? newState
          : prevState;
      });

      //drawCanvasFrame(prediction);
    } else {
      setDetected(false);
      clearCanvasFrame();
    }
  };

  const handlerResetDetect = () => {
    setLivenessDetected(false);
    videoEngine.reset();
    clearCanvasFrame();
    setDetected(false);
    setBestPrediction(null);
    setPhotoDrawInCanvas(false);
    setImageData();
    setIsValidPhoto(false);
    setPhotovalidationmessage();
    setValidating(false);
    setBestShotConditions({
      eyesDistanceInRequiredRange: false,
      poseInRequiredRange: false,
    });
    setLivenessConditions({
      headWasTurned: false,
      eyesWasBlinking: false,
    });
    setHeadTurns({
      up: false,
      down: false,
      left: false,
      right: false,
    });

    const timer = setTimeout(() => {
      resetDetect();
    }, 3000);
    return () => clearTimeout(timer);
  };

  const handlerStartDetect = async () => {
    setInitialization(true);
    await videoEngine.start(input, detectionCallback);
  };

  const onClickClose = async() => {
    vidOff();
    handlerResetDetect();
    if(navigationContext.fromPage === PageUrl.EnquiryResult){
      if (imageContext.imageBlob) {
        const file = await saveFile(imageContext.imageBlob, registrationContext.uinPassport)
        if (file !== null) {
          await patchContextRegistrationAttachment(file);
          await updateRegistration(registrationContext);
          await putContextRegistration(registrationContext)
          navigate(PageUrl.EnquiryResult)
        }
      }
    }
    else {
      navigationContext.fromPage = PageUrl.Camera;
      navigate(PageUrl.Registration)
    }
  };

  const resetDetect = () => {
    handlerStartDetect();
  };

  const checkTurnsHead = (prediction) => {
    const { pose } = prediction;
    const needAngleTurn = 15;
    setHeadTurns((prev) => {
      if (!prev.up) {
        if (pose.axes.pitch > needAngleTurn) return { ...prev, up: true };
        else return prev;
      }
      if (!prev.down) {
        if (pose.axes.pitch < -needAngleTurn) return { ...prev, down: true };
        else return prev;
      }
      if (!prev.left) {
        if (pose.axes.yaw > needAngleTurn) return { ...prev, left: true };
        else return prev;
      }
      if (!prev.rigth) {
        if (pose.axes.yaw < -needAngleTurn) return { ...prev, right: true };
        else return prev;
      }
      return prev;
    });
  };

  const handlerStopDetect = () => {
    const newBestPredict = videoEngine.stop();
    //clearCanvasFrame();
    if (newBestPredict) setBestPrediction(newBestPredict);
    setDetected(false);
  };



  return (
    <Container maxWidth="xl">
      <div>
        <div className="dark-gray-box">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h4>CAMERA</h4>
              </div>
              <div className="col-6">
                <button
                  className="btn-outline-white float-end"
                  onClick={onClickClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="inner-content">
          <div className="container">
            <div className="row ins-cnt">
              <div className="col-lg-3">
                <h3>Instructions</h3>
                <p>
                  Before taking photo you must do the following criteria to
                  ensure that the picture captured is authentic and taken by a
                  human. Use a white background.
                </p>

                <div className="row mt-3">
                  <div className="col-md-8">
                    <span>
                      <h4>STEP 1</h4>
                      <p>Slowly turn head sideways.</p>
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span className="img-right">
                      <img
                        src="/ClientApp/build/Images/Icons/icon-head.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-8">
                    <span>
                      <h4>STEP 2</h4>
                      <p>Blink your eyes.</p>
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span className="img-right">
                      <img
                        src="/ClientApp/build/Images/Icons/icon-eye.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img-holder-big">
                  {/* <img src="Images/sample_big-photo.png" alt="" className="img-fluid" /> */}
                  <Container maxWidth="xl">
                    {isDetected && (
                      <Assistant
                        bestshotConditions={bestShotConditions}
                        livenessConditions={livenessConditions}
                        headTurns={headTurns}
                      />
                    )}
                    <Grid
                      className="wrapper"
                      justify="center"
                      container
                      spacing={3}
                    >
                      <Grid align="center" item xs={12}>
                        <Grid
                          alignItems="flex-start"
                          justify="center"
                          spacing={2}
                          container
                          style={{ minHeight: "400px" }}
                        >
                          <Grid item>
                            {loading && <CircularProgress />}
                            <Box hidden={loading} className="video__wrapper">
                              {isDetected && !isPersonLivenessDetected && (
                                <>
                                  <Typography align="left">
                                    Wait, detection is in progress...
                                  </Typography>
                                  <DetectionProgressTimer
                                    callback={() => {
                                      const newBestPredict = videoEngine.stop();
                                      if (newBestPredict)
                                        setBestPrediction(newBestPredict);
                                      setDetected(false);
                                      //clearCanvasFrame();
                                    }}
                                    maxCount={10}
                                    isDetected={isDetected}
                                  />
                                </>
                              )}
                              {countDownStarted && (
                                <div>
                                  <p>
                                    Now please smile to the camara. Your photo
                                    will be taken in{" "}
                                    <h4 className="counter">{countDown}</h4>
                                    Seconds
                                  </p>
                                </div>
                              )}
                              <div>
                                {validatingPhoto && (
                                  <div>
                                    <img src="/ClientApp/build/Images/validating.gif"></img>
                                    <span>
                                      Validating image..... Please wait
                                    </span>
                                  </div>
                                )}
                              
                              </div>
                              <video
                                ref={videoRef}
                                id={videoId}
                                hidden={isPhotoDrawnInCanvas}
                              ></video>

                              {imageData && isPhotoDrawnInCanvas && (
                                <img
                                  id={"bestImage"}
                                  alt="Best shot"
                                  className="captured_image"
                                  src={imageData}
                                />
                              )}
                            </Box>
                            <Box>
                              <canvas
                                id={canvasId}
                                hidden={isPhotoDrawnInCanvas}
                              ></canvas>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                      <Grid item>
                        <Button
                          className={"primary-btn"}
                          disabled={loading}
                          onClick={handlerResetDetect}
                          variant="contained"
                        >
                          Re-Detect
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
                <div>
                  <p className={isValidPhoto? "face-success-msg":"face-error-msg"} >{photovalidationmessage}</p>
                </div>
              </div>
              <div className="col-lg-3">
                <h3>Criterias</h3>
                <label className="container-new">
                  Head Turned
                  <input
                    type="checkbox"
                    checked={livenessConditions.headWasTurned}
                  />
                  <span className="checkmark" />
                </label>
                <br />
                <label className="container-new">
                  {" "}
                  Eyes Blinked
                  <input
                    type="checkbox"
                    checked={livenessConditions.eyesWasBlinking}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
          <div className="capture-cnt">
            {/* <button className="camera-btn-inactive"><img src="Images/Icons/icon-camera-white.svg" alt="" />Take Photo</button> */}
            {/* <Controls handlerTakePhoto={handlerTakePhoto} handleResetPhotoTaken={handleResetPhotoTaken} takenPhoto={isPersonLivenessDetected} livenessConditions={livenessConditions} /> */}
            <>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={initialization && !isInitialized}
                message="Initializing, please wait...."
              />
            </>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p className="text-l-white">
                  © 2021 Singapore Food Agency
                  <br />
                  <a className="text-mycolor" href="#">
                    Report Vulnerability
                  </a>{" "}
                  |{" "}
                  <a className="text-mycolor" href="#">
                    Privacy Statement
                  </a>{" "}
                  |{" "}
                  <a className="text-mycolor" href="#">
                    Terms of Use
                  </a>
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="icon-bar">
                  <a
                    href="https://www.facebook.com/SGFoodAgency"
                    target="_blank"
                    aria-label="facebook"
                  >
                    <img
                      src="/ClientApp/build/Images/Icons/icon-fb.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://twitter.com/SGFoodAgency"
                    target="_blank"
                    aria-label="twitter"
                  >
                    <img
                      src="/ClientApp/build/Images/Icons/icon-twit.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/SGFoodAgency/"
                    target="_blank"
                    aria-label="instagram"
                  >
                    <img
                      src="/ClientApp/build/Images/Icons/icon-insta.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://https://t.me/sgfoodagency"
                    target="_blank"
                    aria-label="telegram"
                  >
                    <img
                      src="/ClientApp/build/Images/Icons/icon-tele.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/SGFoodAgency"
                    target="_blank"
                    aria-label="youtube"
                  >
                    <img
                      src="/ClientApp/build/Images/Icons/icon-yt.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Container>
  );
}
export default CameraScreen;
