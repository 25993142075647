import moment from "moment";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Footer, Header, Profile } from "../../components";
import { AppContext } from "../../contexts";
import { InternetUserType } from "../../types/enum/user-type";
import { PageUrl } from "../../util/app-config";

function EnquiryNotFound() {
  const appContext = useContext(AppContext);
  const registration = appContext.registration;
  const internetUser = appContext.internetUser;
  const navigate = useNavigate();

  const onClickRegister = () => {
    navigate(PageUrl.Registration)
  }

  const onClickEnquireAgain = () => {
    if (internetUser === null) {
      navigate(PageUrl.Enquiry)
    }
    else if (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider) {
      navigate(PageUrl.Company)
    } else {
      navigate(PageUrl.Enquiry)
    }
  }

  const onClickBack = () => {
    navigate(PageUrl.Home)
  }

  const visibleProfile = () => {
    return internetUser !== null && internetUser.type !== InternetUserType.Enquery;
  }

  const visibleRegisterButton = () => {
    return internetUser !== null && internetUser.type !== InternetUserType.BusinessOwner && internetUser.type !== InternetUserType.Enquery;
  }

  return (
    <div>
      <div className="gradient-box">
        <Header></Header>
        <Profile isVisibleProfile={visibleProfile()}></Profile>
        <div className="half_bg-img">
          <div className="container">
            <div className="white-box-curved enguiry-page">
              <div className="enguiry-bx">
                {visibleProfile() && internetUser.type !== InternetUserType.BusinessOwner && internetUser?.lastLogin !== undefined && <span className="float-end">Last login:  {moment(internetUser.lastLogin).format('DD/MM/YYYY HH:mm')}</span>}
                <h3>FOOD HANDLER VERIFICATION ENQUIRY</h3>
              </div>
              <div className="enguiry-content">
                <div className="row">
                  <div className="col-md-5">
                    <figure>
                      <img src="/ClientApp/build/Images/user_profile_icon.png" alt="" className="img-fluid" />
                    </figure>
                    <span>Full Name</span>
                    <h4>–––</h4>
                  </div>

                  <div className="col-md-4">
                    <span>UIN / Passport</span>
                    <h4>{registration.uinPassport}</h4>
                  </div>
                  <div className="col-md-3">
                    <span>Date of Birth</span>
                    <h4>{(moment(registration.dob)).format('DD-MMM-YYYY')}</h4>
                  </div>
                </div>
                <h5><strong>Is this person a registered FOOD HANDLER?</strong> <span className="bg-red">NO</span></h5>
              </div>
              <div className="col-md-12">
                {internetUser === null && <button className="secondary-btn" onClick={onClickBack}>Go to Main Page</button>}
                <button className="primary-btn" onClick={onClickEnquireAgain}>Enquire Again</button>
                {visibleRegisterButton() &&
                  <button className="secondary-btn" onClick={onClickRegister}>Register This Person</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default EnquiryNotFound;