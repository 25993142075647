import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Profile } from "../../components";
import { AppContext } from "../../contexts";
import { PageUrl } from "../../util/app-config";

function Success(){
    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const onClickViewRegistration = () => {
        appContext.navigation.fromPage = PageUrl.Success;
        navigate(PageUrl.Registration)
    }

    const onClickBack = () => {
        navigate(PageUrl.Home)
    }

    return(
        <div className="gradient-box">
        <Header></Header>
        <Profile></Profile>
        <div className="half_bg-img">

            <div className="container">
                <div className="white-box-curved success-bg">
              <img src="/ClientApp/build/Images/Icons/icon-congrats.svg"/>
                    <div className="col-md-12">
                        <h4>Congratulations!</h4>
                        <p>You have successfully submitted your registration.</p>
                    </div>
                    <div className="col-md-12">
                        {/*<button className="secondary-btn" onClick={onClickBack}>Back to Main</button>*/}
                        <button className="primary-btn" onClick={onClickViewRegistration}>View Your Registration</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
       
    );
}

export default Success;