import { useNavigate } from "react-router-dom";
import { PageUrl } from "../../util/app-config";
export const Logo = () => {
    const navigate = useNavigate();

    const onClickHome = () => {
        navigate(PageUrl.Home)
    }
    return (
        <div className="col-lg-3 cr-pointer" onClick={onClickHome}>
        <div className="hanging-logo"><img src="/ClientApp/build/Images/sfa_logo.png" alt="" />
            </div>
        </div>
    );
}
