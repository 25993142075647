import { toast } from 'react-toastify';
import { NotifyType } from '../util/app-config';

export const notify = () => {

    const config = {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }

    const message = (type: NotifyType, message: string) => {
        switch (type) {
            case NotifyType.Success:
                toast.success(message, config)
                break;
            case NotifyType.Error:
                toast.error(message, config)
                break;
            case NotifyType.Info:
                toast.info(message, config)
                break;
            case NotifyType.Warning:
                toast.warn(message, config)
                break;
            default:
                toast(message, config)
                break;
        }

    };

    return {
        message: message
    }
}