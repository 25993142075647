import React , { useEffect } from 'react'
import SingpassQRFlowLoginStg from './singpass-qr-flow-stg'
import { useAuthService } from "../../services";
import { useSearchParams } from "react-router-dom";
import "../login/login.css";
import { Header, Footer, Message } from "../../components";
import { ApiUrl } from "../../util/app-config";

function SingpassLogin() {
  const { getSPAuthSettings, createSession,getDiscoveryEndpoint } = useAuthService();
  let [searchParam, setSearchPara] = useSearchParams();

  
  useEffect(() => {
    const handleNavigate = async () => {
      let p1 =  getSPAuthSettings();
      let p2= createSession(searchParam.get("ut"), searchParam.get("lt"));
      let p3=  getDiscoveryEndpoint(ApiUrl.SPOidcDoscoveryEndpoint);
      Promise.all([p1,p2,p3])
      .then(async result=>{
        let settings=result[0];
        let session=result[1];
        let wk=result[2];
        window.location.href = `${wk.authorization_endpoint}?client_id=${settings.clientId}&redirect_uri=${settings.redirectUrl}&scope=openid&response_type=code&state=${session.state}&nonce=${session.nonce}`;
      })

      }
    handleNavigate();
  }, []);

  return (
    <div>Redirecting to Singapss..........</div>
  )
}

export default SingpassLogin