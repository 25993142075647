import moment from "moment";
import { FC, useEffect, useState } from "react";
import { LogModel } from "../../types";

type Props = {
    logs: LogModel[] | undefined | null,
}

export const HistoryLogs: FC<Props> = ({ logs }) => {

    const [filteredLogs, setFilteredLogs] = useState<LogModel[]>([])

    const filterLogs = (option: string) => {
        let dateToFilter = new Date();
        switch (option) {
            case '1Month':
                var oneMonthAgo = moment().subtract(1, 'months')
                const logFor1Month = logs?.filter(x => x.createdOn && moment(x.createdOn) >= oneMonthAgo) ?? [];
                setFilteredLogs(logFor1Month)
                break;
            case '3Month':
                var threeMonthAgo = moment().subtract(3, 'months')
                const logFor3Month = logs?.filter(x => x.createdOn && moment(x.createdOn) >= threeMonthAgo) ?? [];
                setFilteredLogs(logFor3Month)
                break;
            case '6Month':
                var sixMonthAgo = moment().subtract(6, 'months')
                const logFor6Month = logs?.filter(x => x.createdOn && moment(x.createdOn) >= sixMonthAgo) ?? [];
                setFilteredLogs(logFor6Month)
                break;
            default:
                var sevenDaysAgo = moment().subtract(7, 'days')
                const logFor7Days = logs?.filter(x => x.createdOn && moment(x.createdOn) >= sevenDaysAgo) ?? [];
                setFilteredLogs(logFor7Days)
                break;
        }
    }

    const onChangeFilter = (e: any, option: string) => {
        e.currentTarget.checked = true;
        filterLogs(option)
    }

    useEffect(() => {
        filterLogs('7Days')
    }, [])

    return (
        <>
            <div className="col-lg-4">
                <h4>History Log</h4>
            </div>
            <div className="col-lg-8">
                <div className="mb-3 row">
                    <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Transaction period:</label>
                    <div className="col-sm-9">
                        <ul>
                            <li>
                                <div className="form-group">
                                    <input type="radio" id="test1" name="radio-group" onChange={(e) => onChangeFilter(e, '7Days')} defaultChecked={true} />
                                    <label htmlFor="test1">Last 7 days</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="radio" id="test2" name="radio-group" onChange={(e) => onChangeFilter(e, '1Month')} />
                                    <label htmlFor="test2">Last 1 month</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="radio" id="test3" name="radio-group" onChange={(e) => onChangeFilter(e, '3Month')} />
                                    <label htmlFor="test3">Last 3 months</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <input type="radio" id="test4" name="radio-group" onChange={(e) => onChangeFilter(e, '6Month')} />
                                    <label htmlFor="test4">Last 6 months</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <table id="example1" className="table table-striped table-bordered width100">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Transactions Made</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredLogs?.map(x => <tr>
                        <td>{moment(x.createdOn).format('DD/MM/YYYY')}</td>
                        <td>{x.action}</td>
                    </tr>
                    )}
                </tbody>
            </table></>
    );
}