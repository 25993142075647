import axios, { AxiosResponse } from "axios"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../contexts"
import { InternetUserModel, RoleModel, AuthModel } from "../types"
import { AuthParam } from "../types/authParam"
import { AccessToken } from "../types/token"
import { AuthSession } from "../types/authSession"
import { errorLogging, notify } from "../util"
import { ApiUrl, NotifyType, PageUrl, SessionKey } from "../util/app-config"

export const useAuthService = () => {

    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const API_KEY: string = process.env.REACT_APP_API_KEY ?? '';

    const headers = {
        'Content-Type': 'application/json',
        'ApiKey': API_KEY
    }

    const { handleHttpError } = errorLogging();
    const { message } = notify();

    const loginInternetUser = async (internetUser: InternetUserModel, role: RoleModel): Promise<InternetUserModel | null> => {
        let response: AxiosResponse<InternetUserModel> | void;
        internetUser.type = role.internetUserType;
        response = await axios.post(`${SERVER_URL}${ApiUrl.User}`, internetUser, {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Internet Login Failed")
            return null;
        }
    }

    const getAuthSettings = async (url:string): Promise<AuthParam | null> => {
        let response: AxiosResponse<AuthParam> | void;
        response = await axios.get(`${SERVER_URL}${url}`,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "OIDC Configurations Loading Failed")
            return null;
        }
    }

    

    const getDiscoveryEndpoint = async (url:string): Promise<AuthParam | null> => {
        let response: AxiosResponse<any> | void;
        response = await axios.get(`${SERVER_URL}${url}`,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Login discovery endpoint was Failed")
            return null;
        }
    }



    const createSession = async (userType:string,loginType: string): Promise<AccessToken | null> => {
        let response: AxiosResponse<any> | void;
        let sessionData={
            userLoginType:loginType,
            userType:userType
        }
        response = await axios.post(`${SERVER_URL}${ApiUrl.CreateUserSession}`,sessionData,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Error in creating a new login session")
            return null;
        }
    }

    const getSPToken = async (code:string,state: string): Promise<AccessToken | null> => {
        let response: AxiosResponse<AccessToken> | void;
        response = await axios.post(`${SERVER_URL}${ApiUrl.Token}?authCode=${code}&state=${state}`,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Error in user authentication")
            return null;
        }
    }

    const getCPToken = async (code:string,state: string): Promise<any | null> => {
        let response: AxiosResponse<any> | void;
        response = await axios.post(`${SERVER_URL}${ApiUrl.CPToken}?authCode=${code}&state=${state}`,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Error in user authentication")
            return null;
        }
    }

    const createLoginSession = async (sessionData:any,url:string): Promise<AuthSession | null> => {
        let response: AxiosResponse<any> | void;       
        response = await axios.post(`${SERVER_URL}${url}`,sessionData,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Error in creating a new login session")
            return null;
        }
    }

    const getToken = async (tokenRequest:any,tokenUrl:string): Promise<any | null> => {
        let response: AxiosResponse<any> | void;
        response = await axios.post(`${SERVER_URL}${ApiUrl.MyInfoToken}`,tokenRequest,  {
            headers: headers
        }).catch(err => { handleHttpError(err, false) });
        if (response && response.status === 200) {
            return response.data;
        } else {
            message(NotifyType.Info, "Error in user authentication")
            return null;
        }
    }
    
    const logout = () => {
        // Clear the authentication token from localStorage
        localStorage.removeItem(SessionKey.InternetAuthToken);
        navigate(PageUrl.Home);
    }

    return {
        loginInternetUser: loginInternetUser,
        logout: logout,
        getAuthSettings: getAuthSettings ,
        getSPToken:getSPToken,
        getCPToken:getCPToken,
        getDiscoveryEndpoint:getDiscoveryEndpoint,
        createSession: createSession,        
        createLoginSession: createLoginSession,
        getToken:getToken
    }
}