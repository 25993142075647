import React, { useContext, useState, useEffect } from 'react';
import { Grid, Button, Snackbar } from '@material-ui/core/';

import { VideoEngineContext } from '../../contexts/video-engine';
import { drawCanvasFrame, clearCanvasFrame } from './util';

function Controls({ handlerTakePhoto, handleResetPhotoTaken, takenPhoto, livenessConditions }) {
	const {
		input,
		status,
		actions,
		videoEngine,
	} = useContext(VideoEngineContext);

	const { loading } = status;
	const { setDetected, setHeadTurns, setBestPrediction, setLivenessConditions, setBestShotConditions } = actions;
	const [initialization, setInitialization] = useState(false);
	const [isInitialized, setisInitialized] = useState(false);

	useEffect(() => {
		if (videoEngine && !takenPhoto) {
			handlerStartDetect();
		}
	});

	// A function that is called each time during detection processing
	const detectionCallback = (prediction) => {
		if (videoEngine.isInitialised) {
			setInitialization(false);
			setisInitialized(true);
		}

		if (prediction && videoEngine.inProgress) {
			setDetected(true);

			// Check turns head
			checkTurnsHead(prediction);

			// Best prediction and best shot
			const bestPrediction = videoEngine.bestPrediction;
			setBestShotConditions((prevState) => {
				const newState = {
					eyesDistanceInRequiredRange: prediction.face.eyes.eyesDistanceInRequiredRange,
					poseInRequiredRange: prediction.pose.poseInRequiredRange,
				};
				return JSON.stringify(newState) !== JSON.stringify(prevState) ? newState : prevState;
			})
			if (bestPrediction)
				setBestPrediction(bestPrediction);

			// 2D Liveness
			setLivenessConditions((prevState) => {
				const newState = {
					headWasTurned: prediction.headWasTurned,
					eyesWasBlinking: prediction.face.eyes.wasBlinked,
				};
				return JSON.stringify(newState) !== JSON.stringify(prevState) ? newState : prevState;
			})

			drawCanvasFrame(prediction);
		} else {
			setDetected(false);
			clearCanvasFrame();
		}
	};

	const handlerResetDetect = () => {
		handleResetPhotoTaken();
		videoEngine.reset();
		clearCanvasFrame();
		setDetected(false);
		setBestPrediction(null);
		setBestShotConditions({
			eyesDistanceInRequiredRange: false,
			poseInRequiredRange: false,
		});
		setLivenessConditions({
			headWasTurned: false,
			eyesWasBlinking: false,
		});
		setHeadTurns({
			up: false,
			down: false,
			left: false,
			right: false,
		});

		const timer = setTimeout(() => {
			resetDetect();
		  }, 3000);
		  return () => clearTimeout(timer);
	}

	const handlerStartDetect = async () => {
		setInitialization(true);
		await videoEngine.start(input, detectionCallback);
	};

	const resetDetect = () => {
		handlerStartDetect();
	};

	const checkTurnsHead = (prediction) => {
		const { pose } = prediction;
		const needAngleTurn = 15;
		setHeadTurns((prev) => {
			if (!prev.up) {
				if (pose.axes.pitch > needAngleTurn) return { ...prev, up: true };
				else return prev;
			}
			if (!prev.down) {
				if (pose.axes.pitch < -needAngleTurn) return { ...prev, down: true };
				else return prev;
			}
			if (!prev.left) {
				if (pose.axes.yaw > needAngleTurn) return { ...prev, left: true };
				else return prev;
			}
			if (!prev.rigth) {
				if (pose.axes.yaw < -needAngleTurn) return { ...prev, right: true };
				else return prev;
			}
			return prev;
		});
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={initialization && !isInitialized}
				message="Initializing, plase wait...."
			/>
			<Grid container justify="center" spacing={2}>
				{/* <Grid item>
					<Button
						disabled={loading}
						onClick={handlerTakePhoto}
						variant="contained"
						className={(livenessConditions?.eyesWasBlinking && livenessConditions?.headWasTurned) ? 'photbtn' : ''}>
						Take Photo
					</Button>
				</Grid> */}
				<Grid item>
					<Button
					    className={'primary-btn'}
						disabled={loading}
						onClick={handlerResetDetect}
						variant="contained">
						Re-Detect
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default Controls;
