import React, { useEffect } from "react";
import { useAuthService } from "../../services";
import { useSearchParams } from "react-router-dom";
import { ApiUrl } from "../../util/app-config";

function CorppassLogin() {
  const { getAuthSettings,getDiscoveryEndpoint,createSession } = useAuthService();
  let[searchParam,setSearchPara]=useSearchParams();

  useEffect(() => {
    const handleNavigate = async (): Promise<any> => {
    let p1: Promise<any> =  getAuthSettings(ApiUrl.CPAuthSettings);
    let p2: Promise<any> =  getDiscoveryEndpoint(ApiUrl.CPOidcDoscoveryEndpoint);
    let p3:Promise<any> =  createSession(searchParam.get("ut")!,searchParam.get("lt")!);
    Promise.all([p1,p2,p3])
    .then(values=>
        {
            let settings=values[0];
            let discovery=values[1];
            let session=values[2];

            if(discovery &&  discovery.authorization_endpoint && settings && settings.clientId && settings.redirectUrl){
                window.location.href = `${discovery.authorization_endpoint}?client_id=${settings.clientId}&response_type=${settings.responseType}&redirect_uri=${settings.redirectUrl}&response_mode=query&scope=${settings.scope}&state=${session.state}&nonce=${session.nonce}`;
              }
        }).catch(error=>{
            console.log(error);
        })    
    };

    handleNavigate();
  }, []);
  
  return <div>Redirecting to CorpPass..................</div>;
}

export default CorppassLogin;
