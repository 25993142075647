function AccessDenied() {    
    return (
        <div className="bg-img1 logn-bx">
            <div className="login-content">
          <img src="/ClientApp/build/Images/logo_FHD2_Hub.png" alt="" className="img-fluid" />
                <h3>You don't have access to this page!</h3>
            </div>
        </div>
    );
}

export default AccessDenied;