export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <p className="text-l-white">© 2022 Singapore Food Agency
                <br /><a className="text-mycolor" href="https://www.tech.gov.sg/report_vulnerability" target="_blank">Report Vulnerability</a> | <a className="text-mycolor" href="https://www.sfa.gov.sg/privacy-statement" target="_blank">Privacy Statement</a> | <a className="text-mycolor" href="https://www.sfa.gov.sg/terms-of-use" target="_blank">Terms of Use</a></p>
                    </div>
                    <div className="col-md-6 ">
                        <div className="icon-bar">
                <a href="https://www.facebook.com/SGFoodAgency" target="_blank" aria-label="facebook"><img src="/ClientApp/build/Images/Icons/icon-fb.svg" alt=""/></a>
                <a href="https://twitter.com/SGFoodAgency" target="_blank" aria-label="twitter"><img src="/ClientApp/build/Images/Icons/icon-twit.svg" alt=""/></a>
                <a href="https://www.instagram.com/SGFoodAgency/" target="_blank" aria-label="instagram"><img src="/ClientApp/build/Images/Icons/icon-insta.svg" alt=""/></a>
                <a href="https://t.me/sgfoodagency" target="_blank" aria-label="telegram"><img src="/ClientApp/build/Images/Icons/icon-tele.svg" alt=""/></a>
                <a href="https://www.youtube.com/SGFoodAgency" target="_blank" aria-label="youtube"><img src="/ClientApp/build/Images/Icons/icon-yt.svg" alt=""/></a>
                        </div>


                    </div>
                </div>
            </div>
        </footer>
    );
}