
function Savephoto() {
    return (
        <div className="gradient-box">
            <div className="header-wrapper">
                <div className=" cRHbGy">
                    <div className="container">
                        <a href="https://www.gov.sg/" rel="noreferrer noopener" target="_blank"><img src="https://cdn.gowhere.gov.sg/assets/react/icons/logo-masthead.svg" alt="Singapore Mast head"/>&nbsp;<span>A Singapore Government Agency Website</span></a>
                    </div>
                </div>
            </div>
            <div className="white-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                <img src="/ClientApp/build/Images/sfa_logo.png" alt=""/>
                        </div>
                        <div className="col-md-6">
                            <ul className="profile-menu float-end">
                                <li>
                    <h4><img src="/ClientApp/build/Images/Icons/icon-profile.svg" alt=""/> CHRIS WEBBER</h4>
                                </li>
                                <li><button className="tertiary-btn btn">Logout</button></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="half_bg-img">

                <div className="container">
                    <div className="white-box-curved">
                        <div className="cap-photo">
                <img src="/ClientApp/build/Images/sample_photo.png" alt=""/>
                                <span className="green-tick-small">
                  <img src="/ClientApp/build/Images/Icons/icon-green-tick.svg" alt=""/>
                                </span>
                        </div>

                        <div className="col-md-12">

                            <h4>15You have successfully saved this photo</h4>
                        </div>
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <button className="primary-btn">Back to Previous Page</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

}

export default Savephoto;