import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import ReactTooltip from "react-tooltip";
import './tooltip.css'

type Props = {
    message: string,
    id: string,
}

export const ToolTip: FC<Props> = ({ message, id }) => {

    return (
        <><button data-tip data-for={id} className="tooltip-btn">
            <FontAwesomeIcon icon={faCircleInfo} size='lg' className="info-icon" />
        </button>
            <ReactTooltip id={id} place="top" effect="solid" className="tooltip-txt">
                {message}
            </ReactTooltip></>
    );
}
