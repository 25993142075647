export enum InternetUserType {
    FoodHandler = 100,
    TrainingProvider = 200,
    BusinessOwner = 300,
    MeatHandler = 400,
    Enquery = 0,
}

export enum IntranetUserType {
    SFAOfficer = 100,
    UserAdmin = 200,
    UserAccessManager = 300
}

export enum RoleType {
    Internet,
    Intranet
}

