import { Modal } from 'react-bootstrap';
import { FC } from "react";
import './popup.css'

type Props = {
    isVisible: boolean,
    bodyText: string,
    buttonCancelText?: string,
    buttonOkText?: string,
    onClickCancel?: () => void,
    onClickOk?: () => void,
}

export const Popup: FC<Props> = ({ isVisible, bodyText, buttonCancelText, buttonOkText, onClickOk, onClickCancel }) => {

    return (
        <>
            <Modal
                show={isVisible}
                onHide={onClickCancel}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="container">
                    <div className='white-box-curved-popup-reg success-bg'>
              <img src="/ClientApp/build/Images/qus-icon.png" />
                        <div className="col-md-12 body-content">
                            <h4>{bodyText}</h4>
                        </div>
                        <div className="col-md-12">
                            {buttonCancelText && buttonCancelText?.length > 0 && <button className="secondary-btn" onClick={onClickCancel}>{buttonCancelText}</button>}
                            {buttonOkText && buttonOkText?.length > 0 && <button className="primary-btn" onClick={onClickOk}>{buttonOkText}</button>}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
