import React from "react";

export type VideoEngineContextData  = {
    constants: any,
    status: any,
    actions: any, 
    videoEngine: any,
    input: any,
    bestPrediction: any
}

export const defaultVideoEngineContextData: VideoEngineContextData = {
  actions: null,
  constants: null,
  status: null,
  videoEngine: {},
  input: null,
  bestPrediction: null
}

export const VideoEngineContext = React.createContext<VideoEngineContextData>(defaultVideoEngineContextData);