import { FC } from "react";
import Marquee from "react-fast-marquee";
type Props = {
  message?: string,
}
export const Header: FC<Props> = ({ message }) => {
  return (
    <header className='header'>
      <div className="header-wrapper">
        <div className="cRHbGy">
          <div className="container">
            <a href="https://www.gov.sg/" rel="noreferrer noopener" target="_blank"><img src="https://cdn.gowhere.gov.sg/assets/react/icons/logo-masthead.svg" alt="Singapore Mast head" />&nbsp;<span>A Singapore Government Agency Website</span></a>
            <Marquee pauseOnHover={true} gradient={false} style={{ width: "700px", float: "right", color: "red", font: "12px" }}>
              {message}
            </Marquee>
          </div>
        </div>
      </div>
    </header>
  );
}
