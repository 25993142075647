import React, { useEffect } from "react";
import { useAuthService } from "../../services";
import { useSearchParams } from "react-router-dom";
import { ApiUrl,NotifyType } from "../../util/app-config";
import { errorLogging, notify } from "../../util"

function MyInfoLogin() {
  const { getAuthSettings,createLoginSession } = useAuthService();
  let[searchParam,setSearchPara]=useSearchParams();
  const { message } = notify();

  useEffect(() => {
    const handleNavigate = async () => {
      let sessionData={
        userLoginType:searchParam.get("lt"),
        userType:searchParam.get("ut")
    }
    let settings = await getAuthSettings(ApiUrl.MIAuthSettings);
    let session = await createLoginSession(sessionData,ApiUrl.CreateMIUserSession)
    //console.log(session);
    if(settings!=null && settings.baseUrl && settings.clientId && settings.redirectUrl && settings.authEndpoint){
        if(settings.governmentEServiceId!=null)
          window.location.href = `${settings.baseUrl}${settings.authEndpoint}?client_id=${settings.clientId}&attributes=${settings.attributes}&purpose=${settings.purpose}&state=${session.state}&redirect_uri=${settings.redirectUrl}&sp_esvcId=${settings.governmentEServiceId}`
        else
          window.location.href = `${settings.baseUrl}${settings.authEndpoint}?client_id=${settings.clientId}&attributes=${settings.attributes}&purpose=${settings.purpose}&state=${session.state}&redirect_uri=${settings.redirectUrl}`
      }
      else{
        message(NotifyType.Error, "Invalid authentication settings")
      }
    
  }

    handleNavigate();
  }, []);


  
  return <div>Redirecting..................</div>;
}

export default MyInfoLogin;
