import React, { useState, useEffect,useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthService,useContextService } from '../../services';
import { AppContext } from '../../contexts';
import { useNavigate } from 'react-router-dom';
import { PageUrl, SessionKey } from '../../util/app-config';

function AuthCallbackCP() {
  let [searchParams, setSearchParams] = useSearchParams();
  const {getCPToken} = useAuthService(); 
  const { putContextInternetUser} = useContextService();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(()=>{
    var code=searchParams.get("code");
    var state=searchParams.get("state");
    const fetchData=async()=>{
        let token=await getCPToken(code,state);
      //console.log(token.internetUser);
      localStorage.setItem(SessionKey.InternetAuthToken, token.jwt);
        if(token.internetUser){
          await putContextInternetUser(token.internetUser);
          navigate(PageUrl.Company)
        }
        
  }
  fetchData(); 
},[])


  return (
    <div>
      <div>Redirecting.........</div>
    </div>
  );
}

export default AuthCallbackCP;
