import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PageUrl } from "../../util/app-config";
import { AppContext } from '../../contexts';
import { InternetUserType } from "../../types/enum/user-type";
import { useAuthService } from "../../services";

type Props = {
    isVisibleProfile?: boolean
}

export const Profile: FC<Props> = ({ isVisibleProfile }) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { logout } = useAuthService();
  const internetUser = appContext.internetUser;

  const onClickHome = () => {
    if (internetUser.type === InternetUserType.BusinessOwner || internetUser.type === InternetUserType.TrainingProvider) {
      navigate(PageUrl.Company)
    } else if (internetUser.type === InternetUserType.FoodHandler) {
      navigate(PageUrl.Registration)
    }
    else {
      navigate(PageUrl.Home)
    }
  }

  const onClickLogout = () => {
    logout();
  }

  return (
    <div className="white-box">
      <div className="container">
        <div className="row">
          <div className="col-md-6 cr-pointer" onClick={onClickHome}>
            <img src="/ClientApp/build/Images/sfa_logo.png" alt="" />
          </div>
          {/* <div className=" col-xl-6 order-3 order-xl-2">
                        <nav className="navbar main-menu navbar-expand-xl py-0">
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <a className="nav-link cr-pointer" onClick={onClickHome}><i className="fa fa-home" aria-hidden="true"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div> */}
          {isVisibleProfile &&
            <div className="col-md-6">
              <ul className="profile-menu float-end">
                <li>
                <h4><img src="/ClientApp/build/Images/Icons/icon-profile.svg" alt="" /> {internetUser?.name}</h4>
                </li>
                <li><button className="tertiary-btn btn" onClick={onClickLogout}>Logout</button></li>
              </ul>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
