import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer, Header, Profile } from "../../components";
import { AppContext } from "../../contexts";
import { useApiService, useContextService } from "../../services";
import { MeatHandlerStatus } from "../../types/enum/meathandler-status";
import { InternetUserType } from "../../types/enum/user-type";
import { PageUrl } from "../../util/app-config";
import './enquiry.css'

function EnquiryMeatResult() {
  const appContext = useContext(AppContext);
  const registration = appContext.registrationMeat;
  const imageContext = appContext.image;
  const navigationContext = appContext.navigation;
  const internetUser = appContext.internetUser;
  const [isPhotoUploaded, setIsPhotoUploaded] = useState<boolean>(false)
  const [enquireBtnText] = useState<string>('Search Another Meat Handler')
  const [warningMessage, setWarningMessage] = useState<string>('')
  const [pageTitle] = useState<string>('MEAT HANDLER VERIFICATION ENQUIRY')
    const { updateMeatRegistration, saveFile } = useApiService();
  const { patchContextRegistrationAttachment } = useContextService();
  const navigate = useNavigate();

  const onClickEnquireAgain = () => {
    if (internetUser === null) {
      navigate(PageUrl.Enquiry)
    }
    else if (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider) {
      navigate(PageUrl.Company)
    } else {
      navigate(PageUrl.Enquiry)
    }
  }

  const onClickTakePhoto = () => {
    navigationContext.fromPage = PageUrl.EnquiryMeatResult
    navigate(PageUrl.Camera)
  }

  const onClickUpdatePhoto = async () => {
    if (imageContext.imageBlob) {
      const file = await saveFile(imageContext.imageBlob, registration.uinPassport)
      if (file !== null) {
        await patchContextRegistrationAttachment(file);
          await updateMeatRegistration(registration);
        setIsPhotoUploaded(true)
      }
    }
  }

  const onClickBack = () => {
    navigate(PageUrl.Home)
  }

  const visibleProfile = () => {
    return (internetUser !== undefined && internetUser !== null) && (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider);
  }

  const isUserAllowToTakePhoto = () => {
    return registration.status === MeatHandlerStatus.Active && internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider;
  }

  useEffect(() => {
    if ((internetUser !== undefined && internetUser !== null) && (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider)) {
      if (!imageContext.imageBase64 && registration.status === MeatHandlerStatus.Active) {
        setWarningMessage('Meat handler is missing a photo. please take a photo now')
      }
    }
  }, []);

  return (
    <div>
      <div className="gradient-box">
        <Header></Header>

        <Profile isVisibleProfile={visibleProfile()}></Profile>
        <div className="half_bg-img">
          <div className="container">
            <div className="white-box-curved enguiry-page">
              <div className="enguiry-bx">
                {internetUser?.lastLogin !== undefined && <span className="float-end">Last login:  {moment(internetUser.lastLogin).format('DD/MM/YYYY HH:mm')}</span>}
                <h3>{pageTitle}</h3>
              </div>
              {warningMessage.length > 0 &&
                <div className="row">
                  <div className="col-md-12">
                    <br /> <p className="photo-required-message"><i className="fas fa-exclamation-triangle"></i> {warningMessage}</p>
                  </div>
                </div>
              }
              <div className="enguiry-content">
                <div className="row">
                  <div className="col-md-6">
                    <figure>
                      {imageContext.imageBase64 ?
                        <img src={imageContext.imageBase64} width="100" height="100" alt="" className="img-fluid" />
                        :
                        <img src="/ClientApp/build/Images/user_profile_icon.png" alt="" className="img-fluid" />
                      }
                    </figure>
                    <span>Full Name</span>
                    <h4>{registration.name} </h4>
                  </div>

                  <div className="col-md-3">
                    <span>UIN</span>
                    <h4>{registration.uinPassport}</h4>
                  </div>
                  <div className="col-md-3">
                    <span>Date of Birth</span>
                    <h4>{(moment(registration.dob)).format('DD-MMM-YYYY').toLocaleString()}</h4>
                  </div>
                </div>
                <h5><strong>Is this person a registered MEAT HANDLER?</strong>
                  {(() => {
                    switch (registration.status) {
                      case 100: return <span className="bg-red">{registration.statusText}</span>;
                      case 200: return <span className="bg-green">{registration.statusText}</span>;
                      case 300: return <span className="bg-red">{registration.statusText}</span>;
                      case 400: return <span className="bg-red">{registration.statusText}</span>;
                      case 500: return <span className="bg-red">{registration.statusText}</span>;
                      case 600: return <span className="bg-red">{registration.statusText}</span>;
                      default:
                        return <span className="bg-red">Error</span>;
                    }
                  })()}
                  {/*{registration.status !== FoodHandlerStatus.Active ? <span className="bg-red">NO</span> : <span className="bg-green">YES</span>}*/}
                </h5>
              </div>
              <div className="col-md-12">
                {internetUser === null && <button className="secondary-btn" onClick={onClickBack}>Go to Main Page</button>}
                {registration.status === MeatHandlerStatus.Active && isUserAllowToTakePhoto() && !imageContext.imageBase64 && <button className="secondary-btn" onClick={onClickTakePhoto}>Take Photo</button>}
                              {registration.status === MeatHandlerStatus.Active && isUserAllowToTakePhoto() && imageContext.imageBlob && !isPhotoUploaded && <button className="secondary-btn" onClick={onClickUpdatePhoto}>Update Photo</button>}
                <button className="primary-btn" onClick={onClickEnquireAgain}>{enquireBtnText}</button>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Footer></Footer>
    </div >
  );
}

export default EnquiryMeatResult;