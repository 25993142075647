import { Header, Footer, Message } from '../../components'
import { useNavigate } from "react-router-dom"
import { NotifyType,PageUrl } from '../../util/app-config';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { InternetUserType } from '../../types/enum/user-type';
import { notify } from '../../util';
import moment from 'moment';
import { useApiService, useContextService } from '../../services';

function Login() {

  const navigate = useNavigate();
  const { resetAppContext } = useContextService();
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('')
  const role = appContext.role;
  const { message } = notify();
  const { getAlertMessages } = useApiService();


  const onClickEnquiry = () => {
    role.internetUserType = InternetUserType.Enquery
    navigate(PageUrl.Enquiry)
  }

  const onClickBusinessOwner = () => {
    role.internetUserType = InternetUserType.BusinessOwner
    navigate(`${PageUrl.CPLogin}?ut=bo&lt=cp`)
  }

  const onClickTrainingProvider = () => {
    role.internetUserType = InternetUserType.TrainingProvider
    navigate(`${PageUrl.CPLogin}?ut=tp&lt=cp`)
  }

  const spLoginOnClickHandler = () => {
    role.internetUserType = InternetUserType.FoodHandler
    navigate(`${PageUrl.MILogin}?ut=fh&lt=mi`)
  }

  useEffect(() => {
    const getMessage = async () => {
      const msgList = await getAlertMessages();
      if (msgList !== null) {
        var endDate = moment(msgList[0].toDate);
        var currentDate = moment()
        if(currentDate <= endDate){
          setAlertMessage(msgList[0].message)
        }
        // setTimeout(() => { setAlertMessage('') }, 30000);
      }
    }
    if (!isLoading) {
      resetAppContext();
      setIsLoading(true);
      getMessage();
    }
  }, [isLoading]);

  return (
    <div className="bg-img1">
       <Header message={alertMessage}></Header>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 ">
            <div className="big-box-shadow">
              <div className="logo-box-info">
                <img src="/ClientApp/build/Images/sfa_logo.png" alt="" />
                <h3>Welcome to SFA</h3>
                <h2 style={{ color: '#E9510E' }}><b>SFA's Food Handler Digital Data Hub (FHD2Hub)</b></h2>
                <p>The Singapore Food Agency is a statutory board under the Ministry of Sustainability and the Environment that oversees food safety and security in Singapore.</p>
                <p>The Food Handlers Digital Datahub is a platform for Food Handlers, Approved Training Organisations and Food Business Owners to register food handlers who have attended and completed their Food Safety Courses. It acts as SFA's central repository of all food handlers related data and allows the industry and public to easily check if specific food handlers have passed their Food Safety Course training. </p>
              </div>
              
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row mt-mb-40">
              <div className="col-12 col-lg-6 ">
                <div className="img-holder">
                  <img src="/ClientApp/build/Images/img-food-handler.png" alt="" className="desktop-img" />
                  <img src="/ClientApp/build/Images/img-food-handler-big.png" alt="" className="mobile-img" />
                  <button className="btn btn2" onClick={spLoginOnClickHandler}>ENTER</button>
                </div>
                <div className="img-holder1">
                  <img src="/ClientApp/build/Images/img-trainer.png" alt="" className="desktop-img" />
                  <img src="/ClientApp/build/Images/img-trainer-big.png" alt="" className="mobile-img" />
                  <button className="btn btn1" onClick={onClickTrainingProvider}>ENTER</button>
                </div>
              </div>
              <div className="col-12 col-lg-6 ">
                <div className="img-holder">
                  <img src="/ClientApp/build/Images/img-boss.png" alt="" className="desktop-img" />
                  <img src="/ClientApp/build/Images/img-boss-big.png" alt="" className="mobile-img" />
                  <button className="btn btn3" onClick={onClickBusinessOwner}>ENTER</button>
                </div>
                <div className="img-holder1">
                  <img src="/ClientApp/build/Images/img-enquiry.png" alt="" className="desktop-img" />
                  <img src="/ClientApp/build/Images/img-enquiry-big.png" alt="" className="mobile-img" />
                  <button className="btn5" onClick={onClickEnquiry}>ENTER</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Login;