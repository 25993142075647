import moment from "moment";
import { FC } from "react";
import { Course360Model } from "../../types";
import { CourseStatus } from "../../types/enum/course-status";

type Props = {
    course360s: Course360Model[] | undefined | null,
}

export const CourseDetails: FC<Props> = ({ course360s }) => {
    return (
        <><h4>Course Details</h4><span>Your Completed Courses will be displayed here</span><table id="example1" className="table table-striped table-bordered" >
            <thead>
                <tr>
                    <th>Course Title</th>
                    <th>Passed Date</th>
                    <th>Validity Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {course360s?.map(x =>
                    <tr key={x.id}>
                        <td>{x.courseText}</td>
                        <td>{moment(x.issuedOn).format('DD/MM/YYYY')}</td>
                        <td>{x.expiredOn ? moment(x.expiredOn).format('DD/MM/YYYY') : '-'}</td>
                        {x.status === CourseStatus.Pass && <td className="status-active">{CourseStatus[x.status]}</td>}
                        {x.status !== CourseStatus.Pass && <td className="status-inactive">{CourseStatus[x.status]}</td>}
                    </tr>
                )}
            </tbody>
        </table></>
    );
}