import { notify } from ".";
import { NotifyType } from "./app-config";

export const errorLogging = () => {

    const { message } = notify();

    const handleHttpError = (error: any, showMessage: boolean = false) => {
        if (error.response) {
            console.log(`received response with code ${error.response}`);
        } else if (error.request) {
            console.log(`request was made but no response ${error.request}`);
        } else {
            console.log(error.message);
        }
        if (showMessage) {
            message(NotifyType.Error, 'Something went wrong!')
            throw new Error('something went wrong'); // no response received by the api service
        }
    }

    return {
        handleHttpError: handleHttpError
    }
}