import React, { useContext, useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core/';
import { VideoEngineContext } from '../../contexts/video-engine';

const conditionsActions = {
	headWasTurned: 'Turn your head',
	eyesWasBlinking: 'Blink your eyes',
	// eyesDistanceInRequiredRange: 'Please come closer to the camera',
	// poseInRequiredRange: 'Please look at the camera',
};

const turnsText = {
	up: 'Please turn your head up',
	down: 'Please turn your head down',
	left: 'Please turn your head to the left',
	right: 'Please turn your head to the right',
};

function Assistant({ bestshotConditions, livenessConditions, headTurns }) {
	const { status } = useContext(VideoEngineContext);
	const { isDetected } = status;
	const [message, setMessage] = useState(() => '');

	useEffect(() => {
		// const allHeadTurns = Object.values(headTurns).every((item) => item);
		const otherConditions = { headWasTurned: livenessConditions.headWasTurned, eyesWasBlinking: livenessConditions.eyesWasBlinking};
		if (isDetected) {
			checkConditions(otherConditions, conditionsActions);
		}
		if (!isDetected && message) setMessage('');
		const allConditionsMet = Object.values(otherConditions).every((item) => item);
		if (allConditionsMet) setMessage('');
	}, [isDetected, JSON.stringify(livenessConditions), message]);

	// useEffect(() => {
	// 	const allHeadTurns = Object.values(headTurns).every((item) => item);
	// 	const otherConditions = { ...bestshotConditions, eyesWasBlinking: livenessConditions.eyesWasBlinking };
	// 	if (isDetected) {
	// 		if (!allHeadTurns) checkConditions(headTurns, turnsText);
	// 		else checkConditions(otherConditions, conditionsActions);
	// 	}

	// 	if (!isDetected && message) setMessage('');
	// 	const allConditionsMet = Object.values(otherConditions).every((item) => item);
	// 	if (allConditionsMet && allHeadTurns) setMessage('');
	// }, [isDetected, JSON.stringify(bestshotConditions), JSON.stringify(livenessConditions), JSON.stringify(headTurns), message]);

	const checkConditions = async(conditions, objectText) => {
		for (const key in conditions) {
			if (conditions.hasOwnProperty(key)) {
				const value = conditions[key];
				if (!value) {
					setMessage(objectText[key]);
					return;
				}
			}
		}
	};

	return (
		// <Snackbar
		// 	anchorOrigin={{
		// 		vertical: 'top',
		// 		horizontal: 'center',
		// 	}}
		// 	open={Boolean(message)}
		// 	message={message}
		// />
		<div className='detect-text'>
			{Boolean(message) && 
			<span>
				<h3>{message==conditionsActions.headWasTurned ? 1 : 2}</h3><br></br>
				<h4>{message}</h4>
			</span>}
		</div>
	);
}

export default React.memo(Assistant);
