import React, { useState, useEffect,useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthService } from '../../services';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { InternetUserModel } from '../../types';
import { InternetUserType } from '../../types/enum/user-type';
import { notify } from '../../util';
import { NotifyType, PageUrl } from '../../util/app-config';
import { useApiService, useContextService } from '../../services';
import { AppContext } from '../../contexts';

function AuthCallback() {
  let [searchParams, setSearchParams] = useSearchParams();
  const {getToken} = useAuthService(); 
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { loginInternetUser, getRegistrationOnEnquiry, getRegistrationById } = useApiService();

  const [isVisiblePassword, setIsVisiblePassword] = useState(false)
  const { putContextInternetUser, putContextRegistration, patchContextRegistration } = useContextService();
  const { message } = notify();

  useEffect(()=>{
    var code=searchParams.get("code");
    var state=searchParams.get("state");
    const fetchData=async()=>{
        let tokenResponse=await getToken(code,state);
        //console.log(tokenResponse)
        if (tokenResponse && tokenResponse.isValidUser && tokenResponse.internetUser) {
          await putContextInternetUser(tokenResponse.internetUser);
          if (tokenResponse.internetUser.type == InternetUserType.FoodHandler) {
              const regOnEnquirey = await getRegistrationOnEnquiry(tokenResponse.nric);
              if (regOnEnquirey) {
                  const regOnId = await getRegistrationById(regOnEnquirey.id)
                  if (regOnId) {
                      await putContextRegistration(regOnId, true);
                      navigate(PageUrl.Registration)
                  }
              } else {
                  await patchContextRegistration(tokenResponse.nric);
                  navigate(PageUrl.Registration)
              }
          } else {
              navigate(PageUrl.Company)
          }
      } else {
          message(NotifyType.Error, "User Login Failed")
      }
  }
  fetchData(); 
},[])


  return (
    <div>
      <div>Redirecting.........</div>
    </div>
  );
}

export default AuthCallback;
